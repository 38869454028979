.buttonContainer {
    position: relative;
    display: inline-block;

    button {
        text-transform: unset;
    }

    .chatIcon {
        position: absolute;
        top: 2px;
        right: 2px;
        font-size: 14px;
        color: #fff;
    }
}

.qsrOrderDetailActions {
    @media (max-width: 720px) {
        flex-direction: column;
        gap: 8px;
        button {
            width: 100%;
        }
    }
}
